.btn {
  &__download {
    position: relative;
    display: block;
    background: #F3F7FC;
    color: #316BA5;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    border: #F3F7FC 1px solid;
    padding: 10px;
    transition: all 0.3s ease;

    &:hover {
      background: #316BA5;
      color: #fff;
    }

    &:before {
      content: "";
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 15px;
      height: 15px;
      background: url('/assets/media/icons/icon-download.png') center/cover no-repeat;
    }
  }
}
