.btn-upload {
  position: relative;
  display: flex;
  color: #306BA5;
  width: 350px;
  padding: 10px;
  border: 1px dashed #4e4e4e;
  border-radius: 4px;
  background: #fff;
  justify-content: center;
  margin-left: 10px;

  img {
    height: 15px;
    margin-left: 5px;
  }
}

.mat-mdc-form-field {
  position: relative;
  width: 100%;
  height: 40px !important;
}

.mat-mdc-form-field-infix {
  width: 100% !important;
  min-height: 40px !important;
  padding: 6px 10px !important;

  input,
  .mat-mdc-select {
    font-size: 12px !important;
  }
}

.mat-mdc-text-field-wrapper {
  height: 40px !important;
}

.mdc-text-field {
  padding: 0 !important;
  overflow: visible !important;
}

.mat-mdc-text-field-wrapper {
  z-index: 1 !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  z-index: 10 !important;
}

// .mdc-line-ripple,
// .mdc-line-ripple::before {
//   display: none;
// }

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-width: 1px !important;
  border: 1px solid #BDBDBD;
  height: 100%;
  border-radius: 10px;
}

.mat-mdc-form-field-subscript-wrapper {
  position: absolute !important;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  border-color: #4e4e4e !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-color: #f44336;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-width: 2px !important;
  border-color: #306BA5 !important;
}

.mat-mdc-tooltip-trigger {
  top: 0;
  right: 10px !important;
}

.mat-calendar-body-selected {
  background-color: #306BA5 !important;
}

.mat-datepicker-toggle-active {
  color: #306BA5 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
  border-color: #306BA5 !important;
  background-color: #306BA5 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  background-color: #306BA5 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #306BA5 !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: #306BA5 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
