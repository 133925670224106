.mdc-tab {
  // mat-mdc-tab mat-mdc-focus-indicator mdc-tab--active ng-star-inserted mdc-tab-indicator--active
  border: 1px solid #efefef !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #EFEFEF;
  color: #BDBDBD;

  &.mdc-tab--active {
    background-color: #fff;
    border-radius: none !important;
  }
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
  color: #316BA5 !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: transparent !important;
}
