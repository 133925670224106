.app-page {
  background-color: var(--bs-gray-100);
}

.app-header {
  position: relative;
  // width: 80%;
  /*
  @include media-breakpoint-up(lg) {
    width: 40%;
    left: unset !important;
  }*/
}

.text-header {
  position: relative;
  top: 0;
  font-size: 25px;
  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 10px;
    font-size: 25px;
  }
}

a.menu-link.without-sub.active {
  // transition: color .2s ease;
  background-color: #316BA5;
  color: #f5f5f5;
  padding: 0.85rem 0 0.85rem 0.85 !important;
}

.menu-item {
  &.show.active {
    .menu-link.here {
      background-color: #316BA5;
      color: #f5f5f5;
      padding: 0.85rem 0 0.85rem 0.85 !important;
    }
  }
}

.menu-item {
  &.sub-here.show{
    span.menu-link {
      background-color: var(--bs-menu-link-bg-color-active);
      color: var(--bs-menu-link-color-active);
      padding: 0.85rem 0 0.85rem 0.85 !important;
    }
  }
}

.app-sidebar-menu .menu>.menu-item>.menu-link.without-sub.active>.menu-title {
  font-weight: 600 !important;
}

.app-sidebar-menu .menu>.menu-item>.menu-link>.menu-title {
  font-weight: 400;
}

a.menu-link,
span.menu-link {
  color: #000000
}

.menu-selector-background {
  width: 250px !important;
}

.app-sidebar-menu,
.app-sidebar-storage {
  background-color: white;
}

.menu-item {
  padding: 0 0 !important;
}

.mat-mdc-tab {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  background: none;
  font-family: var(--mat-tab-header-label-text-font);
  // font-size: 12px !important;
  letter-spacing: var(--mat-tab-header-label-text-tracking);
  line-height: var(--mat-tab-header-label-text-line-height);
  font-weight: var(--mat-tab-header-label-text-weight);
}
