/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

.bg-dark-mcp {
    background-color: #306BA5 !important;
}

.mcp-text-dark {
    color: #306BA5 !important;
    border: 2px solid #306BA5 !important;
}

.mcp-progress-bg-cyan {
    background-color: #0dcaf0;
}

.mcp-progress-bg-red {
    background-color: #e40d0e;
    color: #ffffff;
}

.mcp-progress-bg-blue {
    background-color: #56a5ff;
}

.mcp-progress-bg-green {
    background-color: #17a379;
    color: #ffffff;
}

.mcp-progress-bg-yellow {
    background-color: #ffc502;
    color: #ffffff;
}

.mcp-rounded {
    //border-radius: 1.5rem !important;
    border-radius: 0.5rem !important;
}

.mcp-rounded-storage {
    border-radius: 1.5rem !important;
}

.mcp-rounded-mini-card {
    border-radius: 1.2rem !important;
}

.mcp-rounded-10 {
  border-radius: 10px;
}

.mcp-progress {
    background-color: transparent !important;
}

.leaflet-control-zoom-in {
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
}

.leaflet-control-zoom-out {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.leaflet-control-zoom {
    border: none !important;
}

.leaflet-container .leaflet-popup-content-wrapper {
    border-radius: 1.5rem !important;
}

.leaflet-container a.leaflet-popup-close-button {
    top: 5px !important;
    right: 8px !important;
}

.my-dialog-class .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 50px !important;
}

.mcp-bullet-bar {
    display: inline-block;
    background-color: var(--bs-bullet-bg-color);
    border-radius: 6px;
    width: 4px;
    flex-shrink: 0;
}

.mat-dialog-container {
    border-radius: 20px !important;
}

.custom .mat-dialog-container {
    border-radius: 50px !important;
}

// table
table .table-heading {
    background-color: #316BA5;
    color: #ffffff;
}

.table-container {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #d1d1d1;

    table {
        border-collapse: separate;
        border-spacing: 0;
        font-size: 0.9rem;

        tr:last-child {
            td:first-child {
                border-bottom-left-radius: 10px;
            }

            td:last-child {
                border-bottom-right-radius: 10px;
            }
        }
    }
}

.mat-mdc-header-cell {
    --mat-table-row-item-outline-color: #316BA5 !important;
    background-color: #316BA5 !important;
    color: #ffffff !important;
    font-weight: bold !important;
}

.mat-sort-header-arrow {
    color: #ffffff !important;
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 20px;
}


.mat-mdc-row {
    --mat-table-row-item-label-text-size: 1rem !important;
}

.mat-mdc-row {
    --mat-table-row-item-container-height: 45px !important;
}
